<template>
  <div style="color: #161c2d;">
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>

    <SectionHeading classname="text-center" text="What is cryptocurrency?"  isMargin="mb-3 mt-5"></SectionHeading>


    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <h3>What is cryptocurrency?</h3>
        <br/>
        <p>Cryptocurrency is digital currency that exists on a decentralized system called the blockchain. Cryptocurrency serves as an umbrella term for all digital currencies. These can be accessed through the use of a crypto wallet.</p>
        <br/>

        <strong style="font-size:22px;">
          How are cryptocurrency and the blockchain related?
        </strong><br/><br>
        <p>In 2016, Fabricio Santos described the concept of the blockchain as a bank vault filled with rows of glass deposit boxes that allow everyone to see the contents of the boxes without being able to access their contents. He continued the metaphor by explaining that when a person opens a new deposit box, they receive a key unique to that box, but making a copy of the key does not duplicate the contents of the box; it only provides access.</p>
        <br/>
        <p>Blockchain technology is unique in that all transactions are permanently etched into the blockchain for anyone to view at any time—the blockchain record can only be added to, and the recorded history can’t be modified. Cryptocurrency relies on blockchain’s decentralized network of computers to validate and verify its ownership.</p>
        <br/>

        <strong style="font-size:22px;">
          The advantages of cryptocurrency
        </strong><br/><br>
        <strong style="font-size:18px;">
          Secure, instant transactions
        </strong><br/>
        <br/>
        <p>Cryptocurrency is rooted in privacy. This means you don’t need to give every entity you transact with your personal information. Using cryptocurrency keeps your financial and personal data protected because it’s not shared with other parties who may want to access your information (think: advertisers and other entities you did not interact with). Cryptocurrency also allows for near-instant transactions. Because users have instant access to the contents of their crypto wallets, it cuts down on the 3-4 days one would typically have to wait for a transfer from a traditional bank. </p>
        <br/>
        <strong style="font-size:18px;">
          The choice to have self custody of your cryptocurrency
        </strong><br/>
        <br/>
        <p>A traditional banking system requires you to trust the entity holding your money. Whether that’s a bank or another payment service, these centralized entities hold and protect your currency, but they may also use it in other ways too. It’s also possible that they can shut down, change their rules, or experience changes in their policies that affect your ability to use or access your funds. </p>
        <br/>
        <p>One benefit of cryptocurrency is that it enables you to remove middlemen through the use of decentralized finance. In decentralized finance, transactions are “trustless” because they don’t require you to put your trust in someone to transact. Similarly, because the transactions are carried out by a network of computers, they are “permissionless” in that they don’t require the permission of a third party. </p>
        <br/>
        <p>Non-custodial wallets, which give users self custody of their cryptocurrency and gave rise to DeFi, require their owners to safeguard their own funds, but they also allow them to access their funds whenever they want.</p>
        <br/>
        <p>But cryptocurrency is not entirely without intermediaries. Cryptocurrency bought and stored in central exchanges like Coinbase and Binance is subject to systems more akin to traditional centralized banking. These organizations act as middlemen while providing their customers with an added layer of convenience for those who may not want the responsibility that comes with a non-custodial wallet. </p>
        <br/>
        <strong style="font-size:18px;">
          Anonymity and privacy
        </strong><br/>
        <br/>
        <p>Privacy is often touted as one of cryptocurrency’s most important features. Transactions using cryptocurrency are considered “pseudonymous.” They aren’t fully anonymous, but they don’t require the use of one’s government name or other identifying information. Instead, all transactions are tied immutably to your wallet’s address. Having confidential and private access to your crypto wallet is a useful tool, particularly for those who are a part of historically marginalized groups. </p>
        <br/>
        <strong style="font-size:22px;">
          Things to keep in mind when using cryptocurrency
        </strong><br/>
        <br/>
        <strong style="font-size:18px;">
          New and constantly evolving
        </strong><br/>
        <br/>
        <p>While blockchain technology was developed in the ‘90s, Bitcoin (the first cryptocurrency) was only first launched in 2009. The technology’s infancy means that everyone is learning how to use it simultaneously (which can be level-setting), but it also means that everyone is experiencing growing pains at the same time. </p>
        <br/>
        <strong style="font-size:18px;">
          It’s decentralized
        </strong><br/>
        <br/>
        <p>Because of blockchain technology’s decentralized nature, there’s no central bank you can call to fix any problems that may surface. This is particularly true if you choose a non-custodial wallet over a custodial wallet. As a refresher, there are two types of crypto wallets: custodial (“hosted”) and non-custodial wallets. Custodial wallets are managed by a third-party company, whereas a non-custodial wallet is not. Non-custodial wallets give you full control, and full responsibility, which is why safeguarding your access key is even more important.</p>
        <br/>

        <strong style="font-size:22px;">
          How do people use cryptocurrency now?
        </strong><br/>
        <br/>
        <p>The breadth and usage of cryptocurrencies have grown significantly since Bitcoin’s introduction into the world in 2009. Now crypto can be used to purchase everything from NFTs (non-fungible tokens) on OpenSea to physical goods or real-world services (McDonald’s now accepts Bitcoin in some regions, and Google Cloud also accepts Bitcoin and Ethereum as payment for cloud infrastructure). </p>
        <br/>
        <strong style="font-size:22px;">
          How might people use cryptocurrencies in the future?
        </strong><br/>
        <br/>
        <p>More and more merchants (and industries) seem poised to begin to accept cryptocurrencies as a form of payment for any purchase. Blockchain technology as a whole is being adopted by additional industries as well, where future applications could range from medical records to mortgages. </p>
        <br/>

        <strong style="font-size:22px;">
          Types of cryptocurrency
        </strong><br/>
        <br/>
        <p>Coins and tokens are two types of cryptocurrency, and there are a few key differences between the two. </p>
        <br/>
        <strong style="font-size:18px;">
          Coins
        </strong><br/>
        <p>Coins are tied to a particular blockchain and operate solely off of the blockchain’s decentralized system, and may be more volatile in nature. (This is different from stablecoins which are set against a more fixed entity, more on this below.) </p>
        <br/>
        <p>These are some of the most widely used tokens:</p>
        <br/>
        <p>Bitcoin (BTC) — The first cryptocurrency, with the largest market cap of any cryptocurrency today. Used to buy goods in some retail stores, car dealerships, or via PayPal. </p>
        <br/>
        <p>Ethereum (ETH) — The decentralized, open-source blockchain is best known for its smart contract functionality, eventually leading to its popularity for NFTs.</p>
        <br/>
        <p>Polygon (MATIC) – As an EVM-compatible Ethereum sidechain, Polygon enables developers to build scalable dApps with low transaction fees.</p>
        <br/>
        <p>Solana (SOL) — Boasting low gas fees and fast transactions, Solana also enables developers to build dApps. Solana uses the Proof-of-History method.</p>
        <br/>
        <p>Avalanche (AVAX) — An eco-friendly cryptocurrency focused on smart contracts.</p>
        <br/>

        <strong style="font-size:18px;">
          Tokens
        </strong><br/>
        <p>Tokens are built on top of blockchains. Stablecoins are a popular type of token, and differ from coins in that they are anchored against an external reference to reduce the volatility of the cryptocurrency.</p>
        <br/>
        <p>Tether (USDT) —The third largest cryptocurrency and the largest stablecoin, pegged to the US dollar. </p>
        <br/>
        <p>USD Coin (USDC) — Available on multiple blockchains, USDC is a digital US dollar that lives natively online. USDC can be exchanged for US dollars at a 1:1 rate. </p>
        <br/>
        <p>Dai (DAI) — A stablecoin that operates on the Ethereum blockchain and is similar to USDC, DAI allows for easy transfer to US dollars. </p>
        <br/>

        <strong style="font-size:22px;">
          What are the advantages of decentralization?
        </strong><br/>
        <br/>
        <p>Blockchain technology is not controlled by a single entity (like a central bank); instead, it’s upheld by the multitude of computer systems (“nodes”) that support the blockchain (this gives it its “decentralized” trait). The responsibility of keeping it secure therefore falls on those validating or mining the blockchain itself through what’s called a “consensus mechanism,” where nodes in the blockchain must agree that a transaction is valid before it’s written to the blockchain. (This is commonly either a Proof-of-Work or a Proof-of-Stake system.) </p>
        <br/>
        <p>Although cryptocurrency is often described as secure, it’s still important to employ best practices for keeping your crypto safe.  Never share your wallet’s seed phrase, be careful when taking actions using your wallet, and make sure to thoroughly evaluate NFTs before buying. If something looks too good to be true, it probably is.</p>
        <br/>

        <strong style="font-size:22px;">
          How do I access my cryptocurrency?
        </strong><br/>
        <br/>
        <p>You can access your cryptocurrency using a crypto wallet. Here’s one important thing to remember: your cryptocurrency doesn’t live in your wallet (the way your cash lives in your wallet); instead, your wallet is a key to unlock access to your crypto that’s stored on the blockchain (the way you would use a debit card to grab cash from an ATM). </p>
        <br/>
        <p>When it comes to supplying your crypto wallet with cryptocurrency, you can buy crypto using an exchange like Binance or Coinbase, but many NFT-compatible wallets support adding cryptocurrency directly using your wallet through services such as Moonpay or Wyre. These are integrated into the wallet and allow you to buy cryptocurrency using a credit or debit card and skip the process of using a crypto exchange. You may be prompted to verify your identity during this process.</p>
        <br/>

        <strong style="font-size:22px;">
          How are cryptocurrency and NFTs related?
        </strong><br/>
        <br/>
        <p>Cryptocurrency and NFTs both rely on blockchain technology. The key difference is that cryptocurrencies are “fungible” (mutually interchangeable) tokens, whereas NFTs are “non-fungible” (unique and not interchangeable) tokens. For example, one Bitcoin is exactly equal to another Bitcoin, but every NFT is unique by definition. </p>
        <br/>
        <p>To purchase an NFT, you’ll need both cryptocurrency and a crypto wallet. Your crypto wallet allows you to access your cryptocurrency and your NFTs , which is why it’s important to ensure that your wallet is compatible with both. NFT transactions happen using cryptocurrency, although many NFTs on OpenSea are available to buy with a credit or debit card. You can read more about how to buy an NFT here.</p>
        <br/>






      </div>
    </section>



    <Footer classname="bg-cus on-dark"></Footer>
    <!--    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"-->
    <!--       v-scroll-to="'#topnav'">-->
    <!--      <arrow-up-icon class="icons"></arrow-up-icon>-->
    <!--    </a>-->
    <!-- Back to top -->
  </div>
</template>
<script>/**
 * Index-1
 */
import storage from "@/storage";
import HeaderMain from "@/components/n1/HeaderMain.vue";
import SectionHeading from "@/components/n1/SectionHeading.vue";
import Footer from "@/pages/n1/Footer.vue";


export default {
  name: "WhatIsCryptocurrency",
  metaInfo: {
    title: 'GDMining',
    link: [
      // { rel: 'canonical', href: 'https://gdmining.com/what-is-cryptocurrency' }
    ]
  },

  data() {
    return {
      data: [],
      login: false
    }
  },
  components:{
    HeaderMain,
    SectionHeading,
    Footer
  },
  created() {
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    }
  }
};
</script>

<style scoped>
.bg-cus {
  background-color: #ffe5d0;
}
</style>


